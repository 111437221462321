<template>
  <div class="aboutBox">
    <div class="aboutNav">
      <img class="aboutBei" src="../assets/img/image52.png" alt="" />
      <h3 class="aboutTitle">你拖我拉</h3>
      <div class="beiMain">
        <!-- <span class="beiMainTitle">项目介绍</span> -->
        <p class="referral animate__animated animate__fadeInUp">
          你拖我拉共享联盟成立于2022年，是由多家有实力的实体企业、和业内高新技术研发团队联手打造的汽车运输/救援实体共享联盟。联盟基于超200万起救援和运输案件的服务，利用人工智能和物联网大数据等技术，实现可视化、智能化、标准化的SaaS系统，提升共享科技应用水平，助力新车运输/道路救援服务和科技应用降本增效，构建开放共赢的汽车救援共享新生态！
        </p>
      </div>
    </div>
    <!-- 公司大事件 -->
    <div class="event">
      <div class="eventBox">
        <h2 class="eventTitle">公司大事件</h2>
        <!-- 时间线 -->
        <div class="timeline">
          <div class="time1" v-for="(item, index) in timeList" :key="index">
            <p
              class="time"
              @click="add = index"
              :class="{ active: add === index }"
            >
              {{ item.time }}
            </p>
            <span
              class="affair"
              @click="add = index"
              :class="{ activer: add === index }"
              >{{ item.affair }}</span
            >
            <i
              class="ring"
              @click="add = index"
              :class="{ action: add === index }"
            ></i>
            <span class="xian"></span>
          </div>
        </div>
        <!-- 事件内容 -->
        <div class="eventMain">
          <div
            class="content"
            v-for="(item, index) in countList"
            :key="index"
            v-show="add === index"
          >
            <img class="contentImg" :src="item.img" alt="" />
            <p class="contentNav">{{ item.nav }}</p>
            <p class="contentTit">{{ item.tit }}</p>
          </div>
          <!-- <img class="leftUp" src="../assets/img/Group4247.png" alt="">
                    <img class="rightNext" src="../assets/img/Group4347.png" alt=""> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "animate.css";
export default {
  data() {
    return {
      add: 0,
      timeList: [
        {
          time: "2022年3月",
          affair: "(公司成立)",
        },
        {
          time: "2022年4月",
          affair: "(入驻百度云智基地)",
        },
        {
          time: "2022年5月",
          affair: "(平台正式上线)",
        },
      ],
      countList: [
        {
          img: require("../assets/img/Frame4454.png"),
          nav: "2022年3月",
          tit: "公司成立",
        },
        {
          img: require("../assets/img/Frame4453.png"),
          nav: "2022年4月",
          tit: "入驻百度云智基地",
        },
        {
          img: require("../assets/img/Frame4377.png"),
          nav: "2022年5月",
          tit: "平台正式上线",
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.aboutNav {
  position: relative;
  height: 640px;
}
.aboutTitle {
  font-size: 48px;
  color: #fdfdfd;
  position: absolute;
  top: 85px;
  left: 72px;
}
.aboutI {
  font-style: normal;
  padding-bottom: 5px;
  border-bottom: 2px solid #fdfdfd;
}
.beiMain {
  position: absolute;
  top: 202px;
  left: 72px;
}
.beiMainTitle {
  font-size: 28px;
  color: #fdfdfd;
}
.referral {
  font-size: 30px;
  width: 550px;
  margin-top: 10px;
  line-height: 38px;
}
.event {
  height: 706px;
  background-color: #fff;
}
.eventBox {
  padding-left: 72px;
  padding-top: 80px;
}
.eventBTitle {
  font-size: 28px;
}

// 公司大事件
.timeline {
  display: flex;
  height: 97px;
  margin-top: 30px;
  box-sizing: border-box;
}
.time1 {
  width: 150px;
  padding-top: 9px;
  text-align: center;
  position: relative;
  margin-right: 88px;
  cursor: pointer;
}
.time {
  font-size: 18px;
  color: rgba(0, 0, 0, 0.45);
}
.affair {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.45);
}
.ring {
  width: 20px;
  height: 20px;
  display: inline-block;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.45);
  position: absolute;
  top: 70px;
  left: 60px;
}
.xian {
  position: absolute;
  top: 79px;
  left: 54px;
  width: 220px;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.45);
}

.eventMain {
  margin-top: 60px;
  position: relative;
}
.countent {
  width: 1296px;
  height: 358px;
}
.contentTit {
  display: inline-block;
  vertical-align: top;
  width: 360px;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.85);
  // margin-left: 72px;
  font-weight: bold;
  margin-top: 8px;
}
.contentNav {
  font-weight: bold;
}
.contentImg {
  float: left;
  margin-right: 72px;
}
.leftUp {
  position: absolute;
  top: 45%;
}
.rightNext {
  position: absolute;
  top: 45%;
  right: 80px;
}
.active {
  font-size: 24px;
  color: #000;
  font-weight: bold;
}
.activer {
  font-size: 16px;
  color: #000;
  font-weight: bold;
}
.action {
  background-color: #000;
}

.time1:last-child .xian {
  height: 0;
  background-color: #fff;
}
</style>